export default {
    VendaPorProduto: ['produtor', 'operador'],
    VendaPorOperacao: ['produtor'],
    VendaPorFormasPag: ['produtor'],
    Financeiro: ['produtor', 'operador'],
    VendaPorUsuario: ['produtor'],
    DevolucaoSaldo: ['produtor'],
    Cortesias: ['produtor'],
    Reimpresoes: ['produtor'],
    EstornoProdutos: ['produtor', 'operador'],
    VendaDetalhada: ['produtor'],
    RecargasOnline: ['produtor'],
    Caixa: ['produtor'],
    Clientes: ['produtor'],
    SangriaSuprimento: ['produtor'],
    EventoConfig: ['produtor'],
    ProdutorHome: ['produtor', 'operador'],
    PageProdutorCadastroCategoria: ['produtor'],
    PageProdutorCadastroProduto: ['produtor'],
    PageProdutorCadastroProdutoPlanilha: ['produtor'],
    PageProdutorCadastroOperacao: ['produtor'],
    //PageProdutorOperacoes: ['produtor'],
    ListaEventos:['produtor', 'representante'],
    editarEvento:['produtor', 'representante'],
    EdicaoOperacao: ['produtor'],
    PageProdutorUsuarios: ['produtor', 'representante'],
    CartoesEvento: ['produtor', 'tecnico'],
    ListaEquipamentos: ['produtor', 'imply', 'tecnico'],
    ImportarEquipamento: ['produtor', 'imply', 'tecnico'],
    ConsumidorHome:['consumidor'],
    ConsumidorRecarga:['consumidor'],
    FormaPagamento:['consumidor'],
    PagamentoCartaoCredito:['consumidor'],
    MinhasRecargas:['consumidor'],
    PageConsumidorCadastroConta:['consumidor'],
    PageConsumidorCadastroUsuario:['consumidor'],
    PageAlterarSenha:['consumidor'],
    PageUsuarios:['imply', 'representante', 'produtor'],
    CarteiraDigital:['imply', 'produtor'],
    PageDashboard:['imply', 'produtor'],
    DevolucaoOnline: ['imply']
}