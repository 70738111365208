import admin from "@/router/admin";
import consumidor from "@/router/consumidor";
import Vue from 'vue';
import VueRouter from 'vue-router';

const LoginView = ()=>import(/* webpackChunkName: "page-login" */ '@/views/login/LoginView.vue')
const AdminView = ()=>import(/* webpackChunkName: "page-login" */ '@/views/login/LoginAdminView.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta:{title:"ElevenCash | Sistema Cashless de Pagamentos e Consumos", isAuth: false},
  },
  {
    path: '/consumidor/recuperar-acesso/:token',
    name: 'PageConsumidorRecuperarAcesso',
    component: () => import('@/views/profiles/consumidor/cadastros/RecuperarAcesso.vue'),
    meta: {title: 'ElevenCash | Recuperar Acesso'},
  },
  {
    path: '/admin',
    redirect: '/admin/login'
  },
  {
    path: '/admin/login',
    name: 'LoginAdmin',
    component: AdminView,
    meta:{title:"ElevenCash | Login", isAuth: false},
  },
  admin,
  consumidor,
  {
    path: '/cardapio/',
    component: () => import(/* webpackChunkName: "page-cardapio" */'@/pages/Cardapio.vue'),
    children: [
      {
        path: ':id',
        name: 'CardapioTemplate',
        component: () => import(/* webpackChunkName: "page-cardapio-template" */ '@/views/CardapioView.vue'),
        meta: {title: 'ElevenCash | Cardápio'}
      }
    ]
  },
  {
    path:'*',
    redirect: '/error'
  },
  {
    path:'/error',
    name: 'PageError',
    component: ()=> import(/* webpackChunkName: "page-error" */ '../views/PageError.vue'),
    meta:{title:"ElevenCash | Erro"}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next)=>{
  document.title = to.meta.title;
  next()
})

export default router