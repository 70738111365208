// Routes
const routeEventos = {
  evento: {
    title: 'mobileBar.events',
    icon: "mdi-party-popper",
    name_path: "ListaEventos",
  },
};

const routeEventosProdutor = {
  evento: {
    title: 'mobileBar.events',
    icon: "mdi-party-popper",
    name_path: "ProdutorEventos",
  },
};

const routeCartao = {
  cartao: {
    title: 'mobileBar.cards',
    icon: "mdi-credit-card",
    name_path: "CartoesEvento",
  },
};

const routeEquipamentos = {
  equipamento: {
    title: 'mobileBar.devices',
    icon: "mdi-cellphone",
    name_path: "ListaEquipamentos",
  },
};

const routeDashboard = {
  dashboard: {
    title: "mobileBar.dashboard",
    icon: "mdi-view-dashboard-outline",
    name_path: "EventoInicio",
  },
};

const routeRelatorios = {
  relatorios: {
    title: 'mobileBar.reports',
    icon: "mdi-note-outline",
    submenus: [
      { title: 'mobileBar.reportsList.salesByProduct', name_path: "VendaPorProduto" },
      { title: 'mobileBar.reportsList.salesByOperation', name_path: "VendaPorOperacao" },
      { title: 'mobileBar.reportsList.salesByPaymentMethod', name_path: "VendaPorFormasPag" },
      { title: 'mobileBar.reportsList.salesByUser', name_path: "VendaPorUsuario" },
      { title: 'mobileBar.reportsList.detailedSales', name_path: "VendaDetalhada" },
      { title: 'mobileBar.reportsList.salesByCashRegister', name_path: "Caixa" },
      { title: 'mobileBar.reportsList.salesByClient', name_path: "Clientes" },
      { title: 'mobileBar.reportsList.financial', name_path: "Financeiro" },
      { title: 'mobileBar.reportsList.courtesies', name_path: "Cortesias" },
      { title: 'mobileBar.reportsList.reprints', name_path: "Reimpresoes" },
      { title: 'mobileBar.reportsList.refunds', name_path: "EstornoProdutos" },
      { title: 'mobileBar.reportsList.balanceRefund', name_path: "DevolucaoSaldo" },
      { title: 'mobileBar.reportsList.onlineRecharge', name_path: "RecargasOnline" },
      { title: 'mobileBar.reportsList.receivables', name_path: "Recebiveis" },
      { title: 'mobileBar.reportsList.cashOutAndSupply', name_path: "SangriaSuprimento" },
      { title: 'mobileBar.reportsList.onlineRefund', name_path: "DevolucaoOnline"} 
    ],
  },
};

const routeProdutos = {
  produtos: {
    title: 'mobileBar.products',
    icon: "mdi-ticket-outline",
    name_path: "PageProdutorCadastroProduto",
  },
};

const routeAgendamentos = {
  agendamentos: {
    title: 'mobileBar.appointments',
    icon: "mdi-calendar-multiselect",
    name_path: "PageProdutorCadastroAgendamento",
    beta: true
  },
};

const routeCategorias = {
  categoria: {
    title: 'mobileBar.categories',
    icon: "mdi-format-list-bulleted-type",
    name_path: "PageProdutorCadastroCategoria",
  },
};

const routeOperacao = {
  operação: {
    title: 'mobileBar.operations',
    icon: "mdi-store-marker",
    name_path: "PageProdutorOperacoes",
  },
};

const routeUsuarios = {
  usuarios: {
    title: 'mobileBar.users',
    icon: "mdi-account",
    name_path: "UsuariosEvento",
  },
};

const routeUsuariosGeral = {
  usuarios: {
    title: 'mobileBar.users',
    icon: "mdi-account",
    name_path: "PageUsuarios",
  },
};

const routeEquipamentosGlobal = {
  equips_global: {
    title: "mobileBar.equipmentByEvent",
    icon: "mdi-cellphone",
    name_path: "RelatorioEquipamentosGlobal",
  },
};

const routeCarteiraDigital = {
  carteiraDigital: {
    title: 'mobileBar.digitalWallet',
    icon: "mdi-wallet-bifold",
    name_path: "CarteiraDigital",
  },
};

const routeDashGerencial = {
  dashGerencial: {
    title: 'mobileBar.elevenDash',
    icon: "mdi-view-dashboard-outline",
    name_path: "PageDashboard",
    beta: true
  },
};

const routeGrupos = {
  grupos: {
    title: "mobileBar.userGroups",
        icon: "mdi-account-group",
        name_path: "PageGrupos",
        beta: true
  },
}

const routeAlterarSenhaAdmin = {
  alterar_senha: {
    title: 'mobileBar.changePassword',
    icon: 'mdi-account',
    name_path: 'PageAdminAlterarSenha',
    beta: true
  }
}

//Perfis
const Imply = {
  // ...routeDashGerencial,
  ...routeEventos,
  ...routeUsuariosGeral,
  ...routeEquipamentosGlobal,
  //...routeGrupos,
  ...routeAlterarSenhaAdmin,
};

const Representante = Imply;

const Tecnico = {
  ...routeCartao,
  ...routeEquipamentos,
  ...routeAlterarSenhaAdmin,
};

const Produtor = {
  ...routeDashboard,
  ...routeCarteiraDigital,
  ...routeRelatorios,
  ...routeProdutos,
  ...routeAgendamentos,
  ...routeCategorias,
  ...routeOperacao,
  ...routeUsuarios,
  ...routeCartao,
  ...routeEquipamentos,
  ...routeEventos,
  ...routeAlterarSenhaAdmin,
};

const ProdutorSemCarteira = {
  ...routeDashboard,
  ...routeRelatorios,
  ...routeProdutos,
  ...routeAgendamentos,
  ...routeCategorias,
  ...routeOperacao,
  ...routeUsuarios,
  ...routeCartao,
  ...routeEquipamentos,
  ...routeEventos,
  ...routeAlterarSenhaAdmin,
};

const ApenasEventos = {
  ...routeEventos,
  ...routeAlterarSenhaAdmin,
};

const Operador = {
  ...routeDashboard,
  relatorios: {
    title: 'mobileBar.reports',
    icon: "mdi-note-outline",
    submenus: [
      { title: "Venda por produto", name_path: "VendaPorProduto" },
      //{title: "Estornos", name_path:'EstornoProdutos'},
      { title: "Financeiro", name_path: "Financeiro" },
    ],
  },
  ...routeAlterarSenhaAdmin,
};

const Consumidor = {
  extrato: {
    title: 'mobileBar.statement',
    icon: "mdi-text-box-outline",
    name_path: "ConsumidorHome",
  },
  dados_pessoais: {
    title: 'mobileBar.personalData',
    icon: "mdi-file-document-multiple-outline",
    name_path: "PageConsumidorCadastroUsuario",
  },
  alterar_senha:{
    title: 'mobileBar.changePassword',
    icon: "mdi-account",
    name_path: "PageConsumidorAlterarSenha",
    novo: true
  }
};

export default {
  ["consumidor"]: Consumidor,
  ["imply"]: Imply,
  ["representante"]: Representante,
  ["tecnico"]: Tecnico,
  ["produtor"]: Produtor,
  ["operador"]: Operador,
  ["ApenasEventos"]: ApenasEventos,
  ["produtorSemCarteira"]: ProdutorSemCarteira,
};
